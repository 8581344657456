
@import './_colors.sass'

@import './_navbar.sass'

$primary: #E7493E
$info: #5177FF

@import 'bulma'
@import 'bulma-pageloader'
@import "~@creativebulma/bulma-divider"

